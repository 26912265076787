<script setup>

</script>

<template>
  <nav class="bg-white dark:bg-gray-900  w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img src="../assets/if_logo_1.svg" class="h-8" alt="MT">
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Meta</span>
      </a>
    </div>
  </nav>

</template>

<style scoped>

</style>

<script>
export default {
  name: 'HeaderCompoment'
}
</script>