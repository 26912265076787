<script setup>

</script>

<template>
  <section style="background-image: url('https://www.dropbox.com/scl/fi/clgk9v0o9qqvrzssy7vzy/27baHo4dN_O.png?rlkey=n2ukporxk2xgohmzdzjd7yskv&st=47ns94sq&dl=1');">
    <div class="h-32 text-center flex items-center justify-center">
      <h1 class="mb-4 text-4xl font-medium leading-none tracking-tight" v-text="title"></h1>
    </div>
  </section>
</template>

<style scoped>

</style>

<script>
import constants from "@/constants";
export default {
  name: 'SectionTitle',
  data() {
    return {
      title: constants.PAGE_TITLE,
    }
  }
}
</script>